/* eslint-disable no-undef */

import {NavigationRoute, registerRoute, } from 'workbox-routing'
import {NetworkOnly, } from 'workbox-strategies'

export default null

declare let self: ServiceWorkerGlobalScope

const navigationRoute = new NavigationRoute(new NetworkOnly())
registerRoute(navigationRoute)

self.addEventListener('install', () => self.skipWaiting())
